import { useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
    Typography,
} from "@material-ui/core";
import useAuth from "../../hooks/useAuth";
import CogIcon from "../../icons/Cog";
import UserIcon from "../../icons/User";

const AccountPopover = () => {
    const anchorRef = useRef(null);
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = async () => {
        try {
            handleClose();
            await logout();
            navigate("/dashboard");
        } catch (err) {
            console.error(err);
            toast.error("Unable to logout.");
        }
    };

    return (
        <>
            <Box
                component={ButtonBase}
                onClick={handleOpen}
                ref={anchorRef}
                sx={{
                    alignItems: "center",
                    display: "flex",
                }}
            >
                <Avatar
                    sx={{
                        height: 25,
                        width: 25,
                    }}
                >
                    IA
                </Avatar>
            </Box>
            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    horizontal: "center",
                    vertical: "bottom",
                }}
                keepMounted
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: { width: 240 },
                }}
            >
                <Box sx={{ p: 2 }}>
                    <Typography color="textPrimary" variant="subtitle2">
                        {user.name}
                    </Typography>
                    <Typography color="textSecondary" variant="subtitle2">
                        Administrator
                    </Typography>
                </Box>
                <Divider />
                <Box sx={{ mt: 2 }}>
                    <MenuItem component={RouterLink} to="/dashboard/settings">
                        <ListItemIcon>
                            <CogIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Settings
                                </Typography>
                            }
                        />
                    </MenuItem>
                </Box>
                <Box sx={{ p: 2 }}>
                    <Button
                        color="primary"
                        fullWidth
                        onClick={handleLogout}
                        variant="outlined"
                    >
                        Logout
                    </Button>
                </Box>
            </Popover>
        </>
    );
};

export default AccountPopover;
