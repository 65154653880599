let config = null;
const prod = {
    API_URL: "https://backend-krmb4gu6pa-uc.a.run.app",
};
const dev = {
    API_URL: "https://backend-ue4ej3oqyq-uc.a.run.app",
};
const sandbox = {
    API_URL: "https://mintapi-sandbox.mitelcloud.com",
};
const local = {
    API_URL: "http://localhost:8080",
};

switch (process.env.REACT_APP_ENV) {
    case "local":
        config = local;
        break;
    case "sandbox":
        config = sandbox;
        break;
    case "dev":
        config = dev;
        break;
    case "prod":
        config = prod;
        break;
}
export default prod;
